import React from 'react'
import Login from '../components/users/Login'

function About() {
  return (
    <div className='hero'>
    <Login />
    </div>
  )
}

export default About
