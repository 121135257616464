import React, { useState } from 'react';
import Plot from 'react-plotly.js';
const REACT_DJANGO = process.env.REACT_APP_DJANGO



class GraphNY  extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {gagelist: {},dateRange: [], table: '', data: [], layout: {}, frames: [], config: {}};
    
  }
  
  async reload () {

      let url = REACT_DJANGO +'/api/nyc/gage/'
      
      //Get authorization token
      var userData= JSON.parse(localStorage.getItem('userInfo'))
      this.token=userData.token
      console.log(this.token)
      
      let date="2022-01-01 00:00:00"
      // const res = await fetch(url);
      // 
      const response = await fetch(url,{
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization':'Bearer '+this.token

            },
            
            body: JSON.stringify({'gagelist': this.props.gagelist, 'dateRange':this.props.dateRange, 'table':this.props.table})
        })
      // console.log(this.state.gagelist)
      // console.log(this.props.table)
      const info = await response.json();
      console.log(info)
      this.setState({
        data: info.data,
        layout: info.layout
      });

    //   const addFeedback = async (newFeedback) => {
    //     const response = await fetch('/feedback',{
    //         method:'POST',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(newFeedback)
    //     })
    //     const data = await response.json()

    //     setFeedback([data, ...feedback])
    // }
      
  }

  async componentDidMount() {
      this.reload();
  }

  render() {
    return (
        <Plot
        data= {this.state.data }
        layout={ this.state.layout}

      />

    );
  }
}

export default GraphNY