const userReducer = (state, action) => {
    switch(action.type) {
        case 'LOGIN':
            return {
                ...state,
                userInfo: action.payload,
                loading: false
            }
        case 'LOGOUT':
            return {
                ...state,
                userInfo: [],
                loading: false,
            }
        case 'REGISTER':
            return {
                ...state,
                loading: false,
            }

        default:
            return state
    }
}

export default userReducer