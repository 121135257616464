import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Mapbox from './pages/Mapbox'
import About from './pages/About'
import User from './pages/User'
import Login from './pages/LoginPage'
import PlotScreenNY17 from './pages/PlotScreenNY17'
import PlotScreenLuling from './pages/PlotScreenLuling'
import PlotScreenDanz from './pages/PlotScreenDanz'
import PlotScreenDanzEvent from './pages/PlotScreenDanzEvent'
import ListLifts from './pages/ListLiftsDanziger'
import PlotScreenSeismo from './pages/PlotScreenSeismo'
import PlotScreenSeismoPrince from './pages/PlotScreenSeismoPrince'
import PlotScreenSeismoNIH from './pages/PlotScreenSeismoNIH'
import Weather from './pages/Weather'
import NotFound from './pages/NotFound'
import Navbar from './components/layout/Navbar'
import Footer from './components/layout/Footer'
import Alert from './components/layout/Alert'
import {GithubProvider} from './context/github/GithubContext'
import {AlertProvider} from './context/alert/AlertContext'
import {UserProvider} from './context/user/UserContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'



function App() {
  return (
    <GithubProvider>
      <AlertProvider>
        <UserProvider>
      <Router>
      <div className="flex flex-col justify-between h-screen">
        <Navbar />

        <main className='container mx-auto px-3 pb-12'>
          <Alert />
          <Routes>
            <Route path='/' element={<Login/>  }/>

            {/* <Route path='/luling' element={<PlotScreenLuling/> }/>

            <Route path='/danziger' element={<PlotScreenDanz/> }/>
            <Route path='/danzEvent' element={<PlotScreenDanzEvent/> }/>
            <Route path='/danzLifts' element={<ListLifts/> }/>

            <Route path='/seismo' element={<PlotScreenSeismo/> }/>
            <Route path='/seismoPrince' element={<PlotScreenSeismoPrince/> }/>
            <Route path='/n40a' element={<PlotScreenSeismoNIH/> }/>  */}

            <Route path='/ny17' element={<PlotScreenNY17/> }/>
            

            <Route path='/weather' element={<Weather/> }/>
            <Route path='/about' element={<About/> }/>
            <Route path='/user/:login' element={<User/> }/> 
            <Route path='/login' element={<Login/> }/>     
            <Route path='/map' element={<Mapbox/> }/>
            <Route path='/notfound' element={<NotFound/> }/>
            <Route path='/*' element={<NotFound/> }/>
          </Routes>
        </main>
        <Footer />
      </div>

    </Router>
    </UserProvider>
      </AlertProvider>

    </GithubProvider>
  );
}

export default App;
