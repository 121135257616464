import React from 'react'
import Graph_danziger_TS from '../components/plots/PlotDanziger'
import GraphDanzigerSkew from '../components/plots/PlotDanzigerEvent'
// import Spinner from '../components/layout/Spinner'

function PlotScreenDanzEvent({eventtime}) {
console.log(eventtime)
const today = new Date()
const start = new Date(today)

start.setDate(start.getDate() - 5)
today.setDate(today.getDate() + 1)
// today.toISOString()
// yesterday.toISOString()
// console.log(`today: ${today.toISOString().split('T')[0]} and yesterday: ${yesterday.toISOString().split('T')[0]} 00:00:00`)

// const start_time = start.toISOString().split('T')[0] + ' 00:00:00'
// const end_time = today.toISOString().split('T')[0] + ' 00:00:00'

const start_time = '2022-03-15 19:00:51'
const end_time = '2022-03-15 20:00:51'



  return (
    <div>
      <h1>Danziger</h1>
      <h2 className='bg-slate-300 text-2xl'>Vertical</h2>
     
      <Graph_danziger_TS gagelist= {{
        "primary" : [ 
          {"gage":"vnw","name":"VNW","table":"Dan501_event"},
        {"gage":"vsw","name":"VSW","table":"Dan601_event","tare":0}] ,
        "secondary" : [ 
          {"gage":"temp_ambient_avg", "name": "tempshort","table":"Dan602","tare":0}], "y_range": [0, 70],"title":"West Vertical"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'Dan602'} />
   

      <Graph_danziger_TS gagelist= {{"primary" : [ {"gage":"vne","name":"VNE","table":"Dan502_event"},{"gage":"vse","name":"VSE","table":"Dan602_event","tare":0}] ,"secondary" : [ {"gage":"temp_ambient_avg", "name": "Temp Amb","table":"Dan602","tare":0}], "y_range": [0, 70],"title":"East Vertical"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'Dan602'} />

      <h2 className='bg-slate-300 text-2xl'>Vertical Skew</h2>
      <GraphDanzigerSkew gagelist= {{"set_one" : [ {"gage_one":"vne","gage_two":"vse","table_one":"Dan502_event","table_two":"Dan602_event","name":"East Skew"},
      {"gage_one":"vnw","gage_two":"vsw","table_one":"Dan501_event","table_two":"Dan601_event","name":"West Skew"}] , "y_range": [0, 70],"title":"Horizontal Skew"}} dateRange={{"dateRange" : [ start_time, end_time] }} config={{"y_axes_range":[0.0,6],"y_axes_label":"Skew (in)"}}/>
   
   <h2 className='bg-slate-300 text-2xl'>Horizontal</h2>

      <Graph_danziger_TS gagelist= {{"primary" : [ {"gage":"tnw","name":"TNW","table":"Dan501_event"},{"gage":"tsw","name":"TSW","table":"Dan601_event","tare":0},{"gage":"lnw_ultra_in","name":"LNW","table":"Dan601_event","tare": 20.2},{"gage":"lsw_ultra_in","name":"LSW","table":"Dan601_event","tare":15.2}] ,"secondary" : [ {"gage":"temp_ambient_avg", "name": "Temp Amb","table":"Dan602","tare":0,"scalar":1}], "y_range": [0, 70],"title":"West Horizontal"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'Dan602'} />

      <Graph_danziger_TS gagelist= {{"primary" : [ {"gage":"lne_ultra_in","name":"LNE","table":"Dan602_event","tare":18.0},{"gage":"lse_ultra_in","name":"LSE","table":"Dan602_event","tare":"17.0"},{"gage":"tse_ultra_in","name":"TSE","table":"Dan602_event","tare":17.0}] ,"secondary" : [ {"gage":"temp_ambient_avg", "name": "Temp Amb","table":"Dan602","tare":0,"scalar":1}], "y_range": [0, 70],"title":"East Horizontal"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'Dan602'} />
   
    </div>
  )


}

export default PlotScreenDanzEvent
