import React, { useRef, useEffect, useState } from 'react';
// import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';



function Mapbox() {

  mapboxgl.accessToken = 'pk.eyJ1IjoicmxpbmRlbmJlcmciLCJhIjoiY2t6MXR6ajBkMHk0cjJ1cWpvZmJnZGlsMCJ9.K8bYUnCw3vyPHY60-IKaDA';
 
// const Map = ReactMapboxGl({accessToken:'pk.eyJ1IjoicmxpbmRlbmJlcmciLCJhIjoiY2t6MXR6ajBkMHk0cjJ1cWpvZmJnZGlsMCJ9.K8bYUnCw3vyPHY60-IKaDA'});

const mapContainer = useRef(null);
const map = useRef(null);
// luling
const [lng, setLng] = useState(-90.3798272);
const [lat, setLat] = useState(29.9430491);
//ny17
// const [lng, setLng] = useState(-75.902249);
// const [lat, setLat] = useState(42.114958);

const [zoom, setZoom] = useState(14.1);

useEffect(() => {
  if (map.current) return; // initialize map only once
  map.current = new mapboxgl.Map({
  container: mapContainer.current,
  style: 'mapbox://styles/mapbox/satellite-v9',
  center: [lng, lat],
  zoom: zoom
  });
  });
   
  useEffect(() => {
  if (!map.current) return; // wait for map to initialize
  map.current.on('move', () => {
  setLng(map.current.getCenter().lng.toFixed(4));
  setLat(map.current.getCenter().lat.toFixed(4));
  setZoom(map.current.getZoom().toFixed(2));
  });
  });
  
  return (
//     <div>
// {/* <Map
//   style="mapbox://styles/mapbox/streets-v9"
//   containerStyle={{
//     height: '50vh',
//     width: '50vw'
//   }}
// >
//   <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
//     <Feature coordinates={[29.9430491, -90.3736045]} />
//   </Layer>
// </Map> */}
// <div ref={mapContainer} className="map-container" />
//     </div>
  <div>
    <div className="sidebar">
    Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
    </div>
    <div ref={mapContainer} className="map-container" />
  </div>
  )
}

export default Mapbox
