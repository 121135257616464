import { FaWaveSquare } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands,duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { Waveform } from '@fortawesome/pro-duotone-svg-icons';


function Navbar({title}) {

  return (
    <nav className='navbar mb-12 shadow-lg bg-neutral text-neutral-content'>
        <div className="container mx-auto">
            <div className="flex-none px-2 mx-2">
                {/* <FaWaveSquare className='inline pr-2 text-3xl'/> */}
                <FontAwesomeIcon icon={solid('waveform')} size="lg" />
               
                <Link to='/' className='text-lg font-bold align-middle'>
                &nbsp; {title}
                </Link>
            </div>

            <div className="flex-1 px-2 mx-2">
                <div className="flex justify-end">
{/* 
                <FontAwesomeIcon icon={solid('waveform')} size="lg" />
                <FontAwesomeIcon icon={duotone('waveform')} size="2x" /> */}
                {/* <FontAwesomeIcon icon={solid('sync')} size="2x" pulse  /> */}
                    <Link to='/' className='btn btn-ghost btn-sm rounded-btn'>
                        Home
                    </Link>

                    
                    {/* <Link to='/map' className='btn btn-ghost btn-sm rounded-btn'>
                        Map
                    </Link> */}
                

                    <Link to='/about' className='btn btn-ghost btn-sm rounded-btn'>
                        About
                    </Link>
                </div>
                
            </div>

        </div>
    </nav>
  )
}

Navbar.defaultProps = {
    title: 'WJE Analytics'
}

Navbar.propTypes = {
    title: PropTypes.string,
}

export default Navbar
