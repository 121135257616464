import { Container } from 'postcss'
import React from 'react'
import GraphNY from '../components/plots/PlotNy'
// import Spinner from '../components/layout/Spinner'

function PlotScreenNY17() {

  const today = new Date()
  const start = new Date(today)
  
  start.setDate(start.getDate() - 2)
  today.setDate(today.getDate() + 1)
  // today.toISOString()
  // yesterday.toISOString()
  // console.log(`today: ${today.toISOString().split('T')[0]} and yesterday: ${yesterday.toISOString().split('T')[0]} 00:00:00`)
  // const start_time = start.toISOString().split('T')[0] + ' 00:00:00'
  const start_time = '2021-08-14 00:00:00'
  const end_time = today.toISOString().split('T')[0] + ' 00:00:00'


  return (
    <div>
      <h1>NY17</h1>
      <h2>Lasers</h2>

 
      <GraphNY gagelist= {{
        "primary" : [ 

          {"gage":"laser_18in_med","name":"Laser_18","table":"ny17_table","threshold":[-2.5,1.0]},
          {"gage":"laser_19in_med","name":"Laser_19","table":"ny17_table","threshold":[-2.5,1.0]},
          {"gage":"laser_29in_med","name":"Laser_29","table":"ny17_table","threshold":[-2.5,1.0]},
          {"gage":"laser_40in_med","name":"Laser_40","table":"ny17_table","threshold":[-2.5,1.0]},
        ] ,
       "y_range": [0, 70],"title":"NY17 Lasers","y_axis_label":"Distance (in)"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'ny17_table'} />

      <GraphNY gagelist= {{
        "primary" : [ 

          {"gage":"temp_18_med","name":"Laser_18","table":"ny17_table","threshold":[-20.0,100.0]},
          {"gage":"temp_19_med","name":"Laser_19","table":"ny17_table","threshold":[-20.0,100.0]},
          {"gage":"temp_29_med","name":"Laser_29","table":"ny17_table","threshold":[-20.0,100.0]},
          {"gage":"temp_40_med","name":"Laser_40","table":"ny17_table","threshold":[-20.0,100.0]},
        ] ,
       "y_range": [0, 70],"title":"NY17 Temps","y_axis_label":"Degrees (F)"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'ny17_table'} />

    </div>
  )


}

export default PlotScreenNY17
